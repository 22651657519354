<template>
  <div style="position: relative;">
    <container>
      <middle-title style="margin-bottom: 40px" title="公司动态" :description="description" />
      <a-row type="flex" :gutter="[32, 32]">
        <a-col v-for="(blog, index) in blogs" :xxl="8" :xl="8" :lg="12" :md="24" :sm="24" :key="index">
          <div style="position: relative">
            <div class="post-date bg-primary">
              <div class="date">{{ blog.day }}</div>
              <div class="month">{{ blog.month }}</div>
              <div class="year">{{ blog.year }}</div>
            </div>
            <div class="img-effect">
              <img :src="blog.img" style="object-fit: cover;" />
            </div>
            <div class="blog-info">
              <h5 class="title"><a>{{ blog.title }}</a></h5>
              <p style="margin-top: 10px" class="description">
                {{ blog.description }}
              </p>
              <a style="font-weight: bold" :href="blog.url" target="blank">
                阅读全文
                <DoubleRightOutlined />
              </a>
              <div class="post-tags clearfix">
                <div class="tag" v-for="(item, i) in blog.tag" :key="i">{{ item }}</div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </container>
  </div>

</template>

<script>
import { DoubleRightOutlined } from '@ant-design/icons-vue'
import MiddleTitle from '@/components/common/MiddleTitle'
export default {
  name: 'BlogGrid',
  components: { MiddleTitle, DoubleRightOutlined },
  data() {
    return {
      img: require('@/assets/image/article/anniversary.jpg'),
      description: '辰宇矢志成为行业领先的环球财务规划服务机构，帮助客户实现不同阶段的财智人生，引领环球财务规划行业快速发展！',
      blogs: [
      {
          year: '2024',
          month: 'Dec',
          day: '12',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/20241212.jpg',
          title: '宇青同行 再创辉煌 | 辰宇集团战略入股青创精英商学息',
          description: '2024年12月12日，在粤港澳大湾区这片充满活力与创新精神的土地上，辰宇集团与青创精英商学达成了战略入股协议，并确立了双方之间的战略合作关系。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247566725&idx=1&sn=c9ee735b6ee69446d238cdcfaf436ad4&chksm=fdb752f5cac0dbe348edc006e07ca23f66d5afd80a72912efed5f5cce6f405df3f203793e02e&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇集团', '公司动态', '战略合作']
        },
        {
          year: '2024',
          month: 'Dec',
          day: '10',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/20241210.jpg',
          title: '盛大开幕 「京」彩绽放 | 辰宇北京分公司开业典礼圆满落幕',
          description: '2024年12月10日，辰宇北京分公司在北京这座繁华都市的心脏地带——朝阳区国贸CBD的三星大厦4805号，隆重举行了开业典礼。辰宇集团的高层领导们齐聚一堂，与北京分公司的全体同仁一道，共同迎接这个充满希望和挑战的新起点。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247565985&idx=1&sn=acb3dadf5cab915265261e7782f919aa&chksm=fdb755d1cac0dcc7edfd0b8843c5c65f97c62e5c2d7c205e48aff3cb4f6b205e371c4e54dbcb&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇北京分公司', '开业典礼']
        },
        {
          year: '2024',
          month: 'Dec',
          day: '6',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/20241206.jpg',
          title: '辰宇出海 | 泰国EEC工业区酒店公寓项目动工仪式圆满举办',
          description: '2024年12月6日，辰宇集团迎来了一个新的里程碑。在泰国EEC工业区，集团首个参与开发的酒店公寓项目正式动工，这标志着辰宇集团的业务版图成功进一步拓展至国际市场。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247566725&idx=2&sn=45ea7d3d9ea3b0e04eeef9caa5ba7380&chksm=fdb752f5cac0dbe30d6bb15adcf0a7cd43d18201683407fc36abf9b1f6a698112d72d8b2e229&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇集团', '泰国EEC工业区酒店公寓项目动工仪式']
        },
        {
          year: '2024',
          month: 'Nov',
          day: '12',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image2.jpg',
          title: '辰宇爱心公益：以爱之名，传递温暖，让善意流转不息',
          description: '在这个寒冷的冬季，辰宇集团与海南世界联合公益基金会携手合作，共同开展了一场“以交换代替捐赠”公益活动。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247564921&idx=1&sn=5697b15b12220cb376e030cc71855801&chksm=fdb76909cac0e01fcfc5ef8bab4401b69791ff80ea0bee71a14d0cfca9e3766f66f407a2c209&token=836726202&lang=zh_CN#rd',
          tag: ['辰宇公益', '以交换代替捐赠']
        },
        {
          year: '2024',
          month: 'Oct',
          day: '19',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image5.jpg',
          title: '深圳站 | 2024秋季海归人才招聘会',
          description: '秋意渐浓的十月，辰宇集团又一次站在了人才汇聚之地。2024年10月19日，我们参加了2024第十八届海归人才招聘会（秋季）。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247563697&idx=3&sn=86d975e9625d42ac6dde5e4eef063fb5&chksm=fdb76ec1cac0e7d75a122ab1545b650a732fae35b4f43799efa53be6d3750e323632453a7a08&token=681297217&lang=zh_CN#rd',
          tag: ['招贤纳士', '海归招聘']
        },
        {
          year: '2024',
          month: 'Oct',
          day: '10',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image6.jpg',
          title: '势起争流 | 辰宇2024年第三季度会议圆满落幕',
          description: '2024年10月10日，来自全国各地的辰宇人齐聚深圳金茂万豪酒店，共同参加了深圳市前海辰宇投资咨询有限公司第三季度会议。在这里，我们见证了无数个日夜的辛勤付出，并目睹了每位辰宇人的成长与突破。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247563399&idx=1&sn=abffba052ef845346ce0f58bc76a6de8&chksm=fdb76ff7cac0e6e1d2558e53d5062a6908fdb74a27e1c093e32528196ade7fada3959821b578&token=681297217&lang=zh_CN#rd',
          tag: ['辰宇集团', '季度会议']
        },
        {
          year: '2024',
          month: 'Sep',
          day: '28',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image1.jpg',
          title: '在黑暗中，让音乐唤醒灵魂 | “大开眼界”黑暗公益音乐话剧圆满结束！',
          description: '2024年9月28日，辰宇集团携手黑暗中对话深圳馆举办了一场一场黑暗公益音乐话剧《大开眼界》，剧情与音符的交织，为参与者带来了一场触动心灵与感官的双重盛宴。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247562551&idx=2&sn=24908cd6c680e79144146166f2d4ce3d&chksm=fdb76247cac0eb519bb2fd8fe3c37100f7d2674ad79f05609e151f2e37b25a33287b0e887d95&token=1803320377&lang=zh_CN#rd',
          tag: ['辰宇公益', '黑暗公益音乐话剧']
        },
        {
          year: '2024',
          month: 'Jun',
          day: '1',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image3.jpg',
          title: '辰宇公益四年，照亮孩子们的追梦之路！',
          description: '正值加油未来项目迎来十周年庆典，辰宇集团深感荣幸被邀请参加这一盛大的活动，并荣获了“善行伙伴”的称号。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247555164&idx=1&sn=1232f1c3e1c90c17561a1879f912f81c&chksm=fdb68f2ccac1063ab4d06f69af05f1456b92ae04d722528d32fb222713651c1e83c26cdb41be&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇公益', '善行伙伴', '月捐计划']
        },
        {
          year: '2024',
          month: 'Apr',
          day: '9',
          img: 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/offcial-site/offcial-site/article/image4.jpg',
          title: '双牙之行 | 世界那么大，我想「宇」你踏上旅途',
          description: '今年我们启程前往西班牙与葡萄牙，踏上这片洋溢着热情与浪漫的大地，在这次旅行中，我们不仅欣赏到了世界各地的美景，更品尝了独具特色的美食。',
          url: 'https://mp.weixin.qq.com/s?__biz=MzU4MjY2NDczNQ==&mid=2247554157&idx=1&sn=aed7e16a8a360ed8e394f4525a362f60&chksm=fdb6831dcac10a0bec4d02217188a4b37152324634c6f974728b26f62b88b4c3d2076897e608&scene=178&cur_album_id=2915260482901753857#rd',
          tag: ['辰宇集团', 'SUNIVERS  CONVENTION', '西班牙', '葡萄牙']
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.post-date {
  z-index: 10;
  color: #fff;
  position: absolute;
  left: 20px;
  top: 0;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;

  & .date {
    font-weight: 600;
    font-size: 15px;
    padding: 10px 5px 5px;
    position: relative;
  }

  & .month {
    position: relative;
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 50%;
      height: 1px;
      background-color: #fff;
      margin: 0 -10px;
      width: 20px;
    }
  }

  & .year {
    margin-top: 8px;
  }

}

.img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;

  img {
    width: 100%;
    height: 170px;
  }
}

.blog-info {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ebebeb;

  & .title {
    margin-bottom: 10px;
  }

  & .post-tags {
    margin-top: 10px;
    border-top: 1px solid #e9e9e9;
    padding-top: 10px;

    & .tag {
      font-size: 13px;
      border: 1px solid #ebebeb;
      padding: 2px 8px 1px;
      color: #777;
      margin: 3px;
      display: inline-block;
    }
  }
}
</style>
